<template>
  <v-dialog v-model="dialog" width="400">
    <v-card id="moveFile">
      <v-card-title>
        {{ items[items.length - 1].text }}
      </v-card-title>
      <div class="back">
        <v-btn
          outlined
          fab
          x-small
          v-if="!isBase"
          icon
          @click="moveVisual('../')"
        >
          <v-icon id="arrow" color="primary">mdi-arrow-left</v-icon>
        </v-btn>
      </div>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon id="iconClose">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row
          v-for="(dir, i) in directories"
          :key="i"
          @click="dir.name === old_route ? null : moveVisual(dir.name)"
          v-bind:class="[
            { disabled: dir.name === old_route },
            { pointer: dir.name !== old_route },
          ]"
          style="color: white"
        >
          <v-col cols="1" class="py-0"
            ><v-icon color="primary" small> $folder</v-icon></v-col
          >
          <v-col style="text-transform: uppercase" cols="10" class="py-0">{{
            clearName(dir.name)
          }}</v-col>
        </v-row>
        <v-row style="color: white" justify="center">
          <v-dialog v-model="dialog_new_folder" width="500">
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                fab
                outlined
                v-on="on"
                icon
                style="position: absolute; top: 23px; right: 20px"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      style="cursor: pointer"
                      v-on="on"
                      v-bind="attrs"
                    >
                      mdi-plus</v-icon
                    >
                  </template>
                  <span>{{ $t("new_folder") }}</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                {{ $t("new_folder") }}
              </v-card-title>
              <div class="close">
                <v-btn icon @click="dialog_new_folder = false" small>
                  <v-icon id="iconClose">mdi-close</v-icon>
                </v-btn>
              </div>
              <v-card-text>
                <v-row justify="center">
                  <v-col cols="10" class="py-0">
                    <label
                      v-text="$t('tittle')"
                      style="line-height: 1; display: block"
                    ></label>
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      v-model="new_folder"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="pt-1 pb-3 pr-5">
                <v-spacer></v-spacer>
                <v-btn
                  @click="dialog_new_folder = false"
                  outlined
                  style="height: 25px; width: 100px"
                  elevation="0"
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  @click="addFolder"
                  style="
                    width: 100px !important;
                    color: #363533;
                    height: 25px;
                    padding-top: 10px !important;
                  "
                  elevation="0"
                >
                  <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
                  {{ $t("save", { name: $t("") }) }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="dialog = false"
          outlined
          style="height: 25px; width: 100px"
          elevation="0"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          @click="move"
          :disabled="old_route === route + '/' + new_route"
          style="
            width: 100px !important;
            color: #363533;
            height: 25px;
            padding-top: 10px !important;
          "
          elevation="0"
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "MoveFileDialog",
  props: ["route"],
  data() {
    return {
      dialog: false,
      old_route: null,
      new_route: null,
      name: null,
      directories: [],
      isBase: false,
      new_folder: null,
      dialog_new_folder: false,
    };
  },
  computed: {
    items() {
      let items = [];
      let total_route = "";
      this.new_route.split("/").forEach((element) => {
        if (element !== "") {
          total_route += element + "/";
          items.push({
            text: element,
            total_route,
            disabled:
              total_route === this.new_route + "/" ||
              total_route === this.new_route,
          });
        }
      });
      return items;
    },
  },
  methods: {
    addFolder() {
      this.new_route += "/" + this.new_folder;
      this.new_folder = null;
      this.dialog_new_folder = false;
      this.fetchCurrentRoute();
    },
    open(file) {
      this.old_route = file;
      this.new_route = "";
      let splitFile = file.split("/");
      for (let i = 0; i < splitFile.length - 1; i++) {
        if (this.new_route == "") {
          this.new_route = splitFile[i];
        } else {
          this.new_route = this.new_route + "/" + splitFile[i];
        }
      }

      this.name = splitFile[splitFile.length - 1];
      this.fetchCurrentRoute();
      this.dialog = true;
    },
    clearName(name) {
      if (name === "../") return name;
      name = name.split("/");
      return name[name.length - 1];
    },
    ...mapActions("files", ["getCurrentRoute", "moveFiles"]), //renameFiles
    move() {
      this.moveFiles({
        new: this.new_route,
        old: this.old_route,
        name: this.name,
        route: this.route,
      }).then((data) => {
        this.dialog = false;

        this.$emit("update", data);
        this.$alert("Se ha movido el documento correctamente");
      });
    },
    moveVisual(dir) {
      if (dir === "../") {
        let routes = this.new_route.split("/");
        //console.log(routes);
        dir = "";
        for (let i = 0; i < routes.length - 1; i++) {
          dir += routes[i];
          if (i < routes.length - 2) {
            dir += "/";
          }
          // //console.log("route", route);
        }
      }

      this.new_route = dir;
      this.fetchCurrentRoute();
    },
    fetchCurrentRoute() {
      this.getCurrentRoute({
        filters: { route: this.new_route },
      }).then((data) => {
        this.new_route = data.actualRoute;
        //console.log("data", data);
        data.directories = data.directories;
        /*.filter(
          (dir) => dir.name !== this.old_route
        );*/
        this.directories = data.directories;
        this.isBase = data.isBase;
      });
    },
  },
};
</script>
<style scoped lang="sass">
.back
  position: absolute

  .v-btn
    position: absolute
    top: -30px
    left: 10px
.disabled
  .v-icon,
  *
    color: grey !important
</style>
<style lang="sass">
#moveFile
  .headline
    font-size: 24px !important
    font-weight: 500 !important
    line-height: 2rem
    letter-spacing: normal !important
    font-family: "Roboto", sans-serif !important
  #arrow
    position: relative
    top: 0 !important
    right: 0 !important
  .close .v-btn
    top: 0
    right: 0px
    position: absolute

    #iconClose
      color: var(--v-primary-base) !important
      position: absolute
</style>